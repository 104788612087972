import React, { useState, useEffect } from "react";
import { Typography, Box, Dialog, IconButton, Divider, Button, List, ListItem, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Layout from "../components/layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ServicePage() {
    const [services, setServices] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    useEffect(() => {
        axios.get('/api/services/')
            .then(response => {
                const sortedServices = response.data.sort((a, b) => a.name.localeCompare(b.name));
                setServices(sortedServices);
            })
            .catch(error => {
                console.error("There was an error fetching the services!", error);
            });
    }, []);

    const handleOpenDialog = (service) => {
        setSelectedService(service);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedService(null);
    };

    const navigate = useNavigate();

    const handleBookNow = () => {
        navigate('/booking', { state: { service: selectedService } });
        handleCloseDialog();
    };

    return (
        <Layout>
            <Box position="relative" my={4}>
                <Typography variant="h4" gutterBottom color="text.primary" ml={2}>
                    Behandelingen
                </Typography>
                <Typography variant="body1" color="text.primary" paragraph>
                    Hier vind je een overzicht van de behandelingen die ik aanbied.
                    Klik op een behandeling voor meer informatie en prijzen.
                </Typography>

                {/* Service List */}
                <List>
                    {services.map((service) => (
                        <ListItem key={service.id} disablePadding>
                            <ListItemButton onClick={() => handleOpenDialog(service)}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h6" color="text.primary">
                                            {service.name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="body2" color="text.secondary">
                                            Duur: {service.duration} minuten | Prijs: €{service.price}
                                        </Typography>
                                    }
                                />
                                <ListItemIcon>
                                    <InfoIcon color="action" />
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                {/* Dialog for Service Details */}
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: '16px',
                            padding: '16px',
                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                            bgcolor: '#E4E2DD',
                        },
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                            {selectedService?.name}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleCloseDialog}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body1" paragraph>
                            {selectedService?.description}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body2">
                            Duur: {selectedService?.duration} minuten | Prijs: €{selectedService?.price}
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleBookNow}
                                sx={{ margin: '0 auto', textTransform: 'none' }}
                            >
                                Boek nu
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </Box>
        </Layout>
    );
}

export default ServicePage;
