import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

// Custom background color
const backgroundColor = '#E4E2DD';

// Styled components for the navbar
const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: backgroundColor,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontWeight: 'bold',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        '&::after': {
            transform: 'scaleX(1)',
            opacity: 1,
        },
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '2px',
        backgroundColor: theme.palette.primary.main,
        transform: 'scaleX(0)',
        transition: 'transform 0.3s ease, opacity 0.3s ease',
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '2px',
        backgroundColor: theme.palette.primary.main,
        transform: 'scaleX(0)',
        transition: 'transform 0.3s ease',
    },
    '&:hover::before': {
        transform: 'scaleX(1)',
    },
    position: 'relative',
}));

function Navbar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <StyledAppBar position="sticky">
            <Container maxWidth="lg">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold', letterSpacing: '1px' }}>
                        Nail Studio Puck
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'center' }}>
                        <StyledButton component={Link} to="/">Home</StyledButton>
                        <StyledButton component={Link} to="/services">Services</StyledButton>
                        <StyledButton component={Link} to="/booking">Booking</StyledButton>
                        <StyledButton component={Link} to="/reviews">Reviews</StyledButton>
                    </Box>
                    {/* Mobile Menu Button */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        backgroundColor: backgroundColor,
                                        width: '100px',
                                    },
                                },
                            }}
                        >
                            <StyledMenuItem onClick={handleMenuClose} component={Link} to="/">Home</StyledMenuItem>
                            <StyledMenuItem onClick={handleMenuClose} component={Link} to="/services">Services</StyledMenuItem>
                            <StyledMenuItem onClick={handleMenuClose} component={Link} to="/booking">Booking</StyledMenuItem>
                            <StyledMenuItem onClick={handleMenuClose} component={Link} to="/reviews">Reviews</StyledMenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </StyledAppBar>
    );
}

export default Navbar;
