import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import BrushIcon from '@mui/icons-material/Brush';
import SpaIcon from '@mui/icons-material/Spa';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const services = [
    {
        icon: <BrushIcon fontSize="large" />,
        title: 'Biab & NN Treatments',
        description: 'Biab & NN Treatments zijn ideale behandelingen voor sterke en gezonde nagels. Biab is een flexibele, sterke gel die de natuurlijke nagels versterkt en beschermt. NN Treatmens richten zich op het voeden en verzorgen van de nagels zonder agressieve producten. Beide behandelingen bevorderen de gezondheid van de nagels en zorgen voor een langdurig resultaat.',
    },
    {
        icon: <SpaIcon fontSize="large" />,
        title: 'Gellak',
        description: 'Gellak is een populaire nagelbehandeling die zorgt voor langdurige, glanzende nagels. Het is een hybride tussen nagellak en gel die wordt uitgehard onder een UV- of LED-lamp. Gellak biedt een sterke, krasbestendige laag die tot wel drie weken mooi blijft.',
    },
    {
        icon: <AutoAwesomeIcon fontSize="large" />,
        title: 'Nail Art',
        description: 'Nail art is een creatieve manier om nagels te versieren met diverse designs en patronen. Het kan variëren van eenvoudige, subtiele accenten tot gedetailleerde, kleurrijke kunstwerken. Nail art kan worden gemaakt met verschillende technieken zoals schilderen, stempelen, of het toevoegen van siersteentjes en glitter.',
    },
];

const ServicesSection = () => (
    <Box textAlign="center" my={6}>
        <Typography
            variant="h4"
            gutterBottom
            sx={{
                fontWeight: 300,
                fontSize: { xs: '2rem', sm: '3rem' },
                lineHeight: '1.2',
                color: 'text.primary',
            }}
        >
            Behandelingen
        </Typography>
        <Grid container spacing={4} justifyContent="center">
            {services.map((service, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            p: 2,
                            borderRadius: '12px',
                            bgcolor: '#E4E2DD', // Light nude background
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow
                            transition: 'transform 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.05)', // Slightly enlarge on hover
                            },
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: '#f8c1c1',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                            }}
                        >
                            {service.icon}
                        </Box>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontWeight: 300,
                                lineHeight: '1.2',
                                color: 'text.primary',
                            }}
                        >
                            {service.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            paragraph
                            sx={{
                                color: 'text.primary',
                                fontWeight: '300',
                            }}
                        >
                            {service.description}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    </Box>
);

export default ServicesSection;
