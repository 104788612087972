import React from 'react';
import { Typography, Box, Link, Divider } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                bgcolor: '#E4E2DD',
                color: '#5d4037',
                py: 4,
                px: { xs: 2, sm: 4 },
                mt: 'auto',
                textAlign: 'center',
            }}
        >
            <Divider sx={{ mb: 2 }} />

            <Typography variant="h6" sx={{ mb: 1 }}>
                Nail Studio Puck
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                Zwavelgeelstraat 29,<br />
                1339 HL Almere
            </Typography>

            <Box sx={{ mb: 2 }}>
                <Link
                    href="https://www.instagram.com/nailstudiopuck/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: 'inherit', mx: 1 }}
                >
                    <InstagramIcon fontSize="large" />
                </Link>
                <Link
                    href="mailto:nailstudiopuck@gmail.com"
                    sx={{ color: 'inherit', mx: 1 }}
                >
                    <MailIcon fontSize="large" />
                </Link>
            </Box>

            <Typography variant="body2">
                &copy; {new Date().getFullYear()} Nail Studio Puck. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
