import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Dialog, IconButton, RadioGroup, Radio, FormControlLabel, Paper, TextField, FormControl, FormHelperText, InputAdornment, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Layout from '../components/layout';
import PhoneIcon from '@mui/icons-material/Phone';
import axios from 'axios';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import Cookies from 'js-cookie';

const BookingFlow = () => {
    const [step, setStep] = useState(1);
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [availabilities, setAvailabilities] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        availability: '',
        description: '',
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [phoneError, setPhoneError] = useState(false);
    const [errors, setErrors] = useState({});
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [weekOffset, setWeekOffset] = useState(0);
    const [isConfirming, setIsConfirming] = useState(false);

    useEffect(() => {
        axios.get('/api/services/')
            .then(response => {
                const sortedServices = response.data.sort((a, b) => a.name.localeCompare(b.name));
                setServices(sortedServices);
            })
            .catch(error => {
                console.error("There was an error fetching the services!", error);
            });
    }, []);

    const handleServiceSelect = (service) => {
        setSelectedService(service);
    };

    const handleNextStep = () => {
        if (step === 1 && selectedService) {
            axios.get(`/api/availability/`)
                .then(response => setAvailabilities(response.data))
                .catch(error => console.error('Error fetching availability:', error));
        }
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const handleDateSelect = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setSelectedDate(date);
        const times = availabilities.filter(avail => avail.date === formattedDate);
        setAvailableTimes(times);
    };

    const handleTimeSelect = (timeId) => {
        setFormData({ ...formData, availability: timeId });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'phone') {
            const phoneValid = /^\+?[0-9]{10,15}$/.test(value);
            setPhoneError(!phoneValid);
        }

        setErrors({ ...errors, [name]: value ? '' : 'Dit veld is verplicht' });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setStep(1);
        setSelectedService(null);
        setSelectedDate(null);
        setAvailableTimes([]);
        setFormData({
            name: '',
            email: '',
            phone: '',
            availability: '',
            description: '',
        });
        setErrors({});
    };

    const handleSubmit = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Dit veld is verplicht';
        if (!formData.email) newErrors.email = 'Dit veld is verplicht';
        if (!formData.phone) newErrors.phone = 'Dit veld is verplicht';
        if (!formData.availability) newErrors.availability = 'Selecteer een tijdslot';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setStep(4); // Go to the confirmation step
    };

    const handleConfirm = () => {
        setIsConfirming(true);
        axios.post('/api/book/', { ...formData, service: selectedService.id }, {
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },
        })
            .then(() => {
                setOpenSuccessDialog(true);
                setAvailabilities((prevAvailabilities) =>
                    prevAvailabilities.filter(
                        (avail) => avail.id !== formData.availability
                    )
                );
                handleDialogClose();
            })
            .catch((error) => {
                let errorMessage = "Er is iets fout gegaan bij het boeken van je afspraak.";
                if (error.response && error.response.data) {
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (Array.isArray(error.response.data) && error.response.data.length > 0) {
                        errorMessage = error.response.data[0];
                    } else if (error.response.data.non_field_errors) {
                        errorMessage = error.response.data.non_field_errors[0];
                    }
                }
                setErrorMessage(errorMessage);
                setOpenErrorDialog(true);
            })
            .finally(() => {
                setIsConfirming(false);
            });
    };

    const isDateAvailable = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        return availabilities.some(avail => avail.date === formattedDate);
    };

    return (
        <Layout>
            <Box textAlign="center" mt={4}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setDialogOpen(true)}
                    sx={{ margin: '0 auto', textTransform: 'none', }}
                >
                    Maak Afspraak
                </Button>

                <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth PaperProps={{ sx: { bgcolor: '#E4E2DD', }, }}>
                    <Box p={3} position="relative">
                        <IconButton
                            onClick={handleDialogClose}
                            sx={{ position: 'absolute', right: 16, top: 16 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {step === 1 && (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Kies een behandeling
                                </Typography>
                                <RadioGroup
                                    value={selectedService?.id || ''}
                                    onChange={(e) => {
                                        const service = services.find(s => s.id === parseInt(e.target.value));
                                        handleServiceSelect(service);
                                    }}
                                >
                                    {services.map(service => (
                                        <FormControlLabel
                                            key={service.id}
                                            value={service.id}
                                            control={<Radio />}
                                            label={
                                                <Box>
                                                    <Typography variant="h6">{service.name}</Typography>
                                                    <Typography variant="body2">€{service.price}</Typography>
                                                </Box>
                                            }
                                        />
                                    ))}
                                </RadioGroup>
                                <Box display="flex" justifyContent="center" mt={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNextStep}
                                        disabled={!selectedService}
                                        sx={{ margin: '0 auto', textTransform: 'none', }}
                                    >
                                        Volgende stap
                                    </Button>
                                </Box>
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Kies een datum
                                </Typography>
                                <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                                    <IconButton
                                        onClick={() => setWeekOffset((prev) => prev - 7)}
                                        sx={{ visibility: weekOffset === 0 ? 'hidden' : 'visible' }}
                                    >
                                        &lt;
                                    </IconButton>
                                    <Box display="flex" overflow="auto" gap={1} alignItems="center">
                                        {Array.from({ length: 7 }).map((_, index) => {
                                            const date = new Date();
                                            date.setDate(date.getDate() + weekOffset + index);
                                            const formattedDate = format(date, 'yyyy-MM-dd');
                                            const isAvailable = isDateAvailable(date);
                                            return (
                                                <Paper
                                                    key={formattedDate}
                                                    onClick={() => handleDateSelect(date)}
                                                    sx={{
                                                        padding: 2,
                                                        textAlign: 'center',
                                                        cursor: isAvailable ? 'pointer' : 'default',
                                                        backgroundColor: selectedDate?.toISOString().split('T')[0] === formattedDate
                                                            ? '#f8c1c1'
                                                            : isAvailable
                                                                ? '#ffffff'
                                                                : '#f0f0f0',
                                                        borderRadius: '8px',
                                                        boxShadow: selectedDate?.toISOString().split('T')[0] === formattedDate
                                                            ? '0px 4px 8px rgba(0,0,0,0.2)'
                                                            : 'none',
                                                        opacity: isAvailable ? 1 : 0.5,
                                                        width: 60,
                                                        height: 80,
                                                    }}
                                                >
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {format(date, 'EEE', { locale: nl })}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {format(date, 'dd', { locale: nl })}
                                                    </Typography>
                                                    <Typography variant="caption" fontSize={12}>
                                                        {format(date, 'MMM', { locale: nl })}
                                                    </Typography>
                                                </Paper>
                                            );
                                        })}
                                    </Box>
                                    <IconButton onClick={() => setWeekOffset((prev) => prev + 7)}>
                                        &gt;
                                    </IconButton>
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" gutterBottom>
                                        Beschikbare tijden
                                    </Typography>
                                    <List>
                                        {availableTimes.map((time) => (
                                            <ListItem key={time.id} disablePadding>
                                                <ListItemButton
                                                    onClick={() => handleTimeSelect(time.id)}
                                                    selected={formData.availability === time.id}
                                                    sx={{
                                                        border: formData.availability === time.id ? '2px solid #f8c1c1' : '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        bgcolor: formData.availability === time.id ? '#f5e6da' : 'transparent',
                                                        marginBottom: '8px',
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                                                                {time.time.split('.')[0]}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mt={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handlePreviousStep}
                                        sx={{ margin: '0 auto', textTransform: 'none', }}
                                    >
                                        Terug
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNextStep}
                                        disabled={!formData.availability}
                                        sx={{ margin: '0 auto', textTransform: 'none', }}
                                    >
                                        Volgende stap
                                    </Button>
                                </Box>
                            </>
                        )}

                        {step === 3 && (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Vul je gegevens in
                                </Typography>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Naam"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                    <FormHelperText>Vul je volledige naam in.</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                    <FormHelperText>Je e-mailadres gebruiken voor bevestigingen.</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Telefoonnummer"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        required
                                        error={phoneError || !!errors.phone}
                                        helperText={phoneError ? 'Voer een geldig telefoonnummer in.' : errors.phone}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText>Gebruik een geldig telefoonnummer.</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Omschrijving"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        multiline
                                    />
                                    <FormHelperText>Geef een korte beschrijving van je verzoek.</FormHelperText>
                                </FormControl>
                                <Box display="flex" justifyContent="space-between" mt={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handlePreviousStep}
                                        sx={{ margin: '0 auto', textTransform: 'none', }}
                                    >
                                        Terug
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        sx={{ margin: '0 auto', textTransform: 'none', }}
                                    >
                                        Volgende stap
                                    </Button>
                                </Box>
                            </>
                        )}

                        {step === 4 && (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Bevestig je afspraak
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Behandeling:</strong> {selectedService?.name}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Datum:</strong> {selectedDate && format(selectedDate, 'dd-MM-yyyy')}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Tijd:</strong> {availableTimes.find(time => time.id === formData.availability)?.time}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Naam:</strong> {formData.name}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Email:</strong> {formData.email}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Telefoonnummer:</strong> {formData.phone}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Omschrijving:</strong> {formData.description}
                                </Typography>
                                <Box display="flex" justifyContent="space-between" mt={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handlePreviousStep}
                                        sx={{ margin: '0 auto', textTransform: 'none', }}
                                    >
                                        Terug
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleConfirm}
                                        disabled={isConfirming}
                                        sx={{ margin: '0 auto', textTransform: 'none', }}
                                    >
                                        Bevestig de afspraak
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                </Dialog>
                {/* Success Dialog */}
                <Dialog
                    open={openSuccessDialog}
                    onClose={() => setOpenSuccessDialog(false)}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: '16px',
                            padding: '16px',
                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                            bgcolor: '#dff0d8',
                        },
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                            Afspraak Bevestigd!
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setOpenSuccessDialog(false)}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="body1" paragraph>
                            Je afspraak is succesvol geboekt.
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenSuccessDialog(false)}
                                sx={{ margin: '0 auto', textTransform: 'none', }}
                            >
                                Sluiten
                            </Button>
                        </Box>
                    </Box>
                </Dialog>

                {/* Error Dialog */}
                <Dialog
                    open={openErrorDialog}
                    onClose={() => setOpenErrorDialog(false)}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: '16px',
                            padding: '16px',
                            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                            bgcolor: '#f8d7da',
                        },
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                            Oeps, iets ging fout!
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setOpenErrorDialog(false)}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="body1" paragraph>
                            {errorMessage}
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenErrorDialog(false)}
                                sx={{ margin: '0 auto', textTransform: 'none', }}
                            >
                                Sluiten
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </Box>
        </Layout >
    );
};

export default BookingFlow;
